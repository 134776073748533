import $ from 'jquery';
import Zuck from 'zuck.js';
import './style.scss';

$(document).ready(function () {
    const stories = $('#stories');

    if (stories.length && $(window).width() > 1024) {
        stories.niceScroll({
            cursorcolor: '#bdbdbd',
            cursorwidth: '5px',
            cursorborder: 'none',
            emulatetouch: true,
            cursordragontouch: true,
            scrollbarid: 'wp-story-scrollbar',
        });
    }

    const storyBody = $('body');

    const buildStory = function (data) {
        new Zuck('stories', {
            backNative: false,
            previousTap: true,
            autoFullScreen: true,
            skin: 'snapgram',
            avatars: true,
            list: false,
            cubeEffect: false,
            openEffect: false,
            paginationArrows: false,
            rtl: false,
            localStorage: true,
            stories: data,
            callbacks: {
                onOpen(storyId, callback) {
                    callback();
                    storyBody.addClass('wp-story');
                },
                onClose(storyId, callback) {
                    callback();
                    storyBody.removeClass('wp-story');
                },
                onNavigateItem(storyId, nextStoryId, callback) {
                    callback();
                },
            },
            template: {
                viewerItemBody(index, currentIndex, item) {
                    return `<div
                            class="item ${item.link ? 'linked-story' : ''} ${
                        item.seen === true ? 'seen' : ''
                    } ${currentIndex === index ? 'active' : ''}"
                            data-time="${item.time}" data-type="${
                        item.type
                    }" data-index="${index}" data-item-id="${item.id}">
                            ${
                                item.type === 'video'
                                    ? `<video class="media" muted webkit-playsinline playsinline preload="auto" src="${item.src}" ${item.type}></video>
                                            <b class="muted">${wpStoryObject.lang.unmute}</b>`
                                    : `<img loading="auto" class="media" src="${item.src}" ${item.type} /> `
                            }
                                        ${
                                            item.link
                                                ? `<a class="wp-story-premium-tip tip link" href="${
                                                      item.link
                                                  }" rel="noopener" target="${
                                                      item.new_tab
                                                  }">
                                            ${
                                                !item.linkText ||
                                                item.linkText === ''
                                                    ? wpStoryObject.lang
                                                          .visitLink
                                                    : item.linkText
                                            }
                                            </a>`
                                                : ''
                                        }
                            </div>`;
                },
                viewerItem(item) {
                    return `<div class="story-viewer">
                    <div class="head">
                      <div class="left">
                        <a class="back"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#ffffff" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></a>
                        <span class="item-preview">
                          <img lazy="eager" class="profilePhoto"
                          src="${item.photo}" alt="Story Item"
                          />
                        </span>
                        <div class="info">
                          <strong class="name">${item.name}</strong>
                          <span class="time">${item.timeAgo}</span>
                        </div>
                      </div>

                      <div class="right">
                        <span class="time">${item.timeAgo}</span>
                        <span class="loading"></span>
                        <a class="close" tabIndex="2"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#ffffff" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></a>
                      </div>
                    </div>

                    <div class="slides-pointers">
                      <div class="wrap"></div>
                    </div>
                  </div>`;
                },
            },
            language: wpStoryObject.lang,
        });
    };

    if (stories.length) {
        fetch(`${wpStoryObject.homeUrl}/wp-json/wp-story/v1/free`)
            .then((res) => res.json())
            .then((data) => {
                buildStory(data);
            })
            .catch((err) => {
                throw err;
            });
    }
});
